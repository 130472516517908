import React from 'react';
import { Box, Heading, Text, Flex, Link } from 'theme-ui';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';

import Section from './section';
import background from 'images/argumentation-background.svg';

const Argumentation = () => {
  const { t } = useTranslation();

  const args = [
    { translation: 'image1', image: 'image1.png', width: 1920, height: 1079 },
    { translation: 'image2', image: 'image2.jpg', width: 2560, height: 1822 },
    { translation: 'image3', image: 'image3.png', width: 1440, height: 1024 },
  ];

  return (
    <Box
      sx={{
        backgroundColor: 'light',
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top left',
        backgroundSize: 'contain',
        paddingTop: [50, 150],
      }}
    >
      <Section>
        {args.map((arg, index) => (
          <Flex
            sx={{
              flexDirection: [
                'column',
                index % 2 === 0 ? 'row' : 'row-reverse',
              ],
              alignItems: 'center',
              justifyContent: 'center',
            }}
            key={arg.translation}
          >
            <Box
              sx={{
                width: ['100%', '60%'],
                my: [4, 5],
                boxShadow: 'large',
              }}
            >
              <Image
                src={`/landing/${arg.image}`}
                width={arg.width}
                height={arg.height}
                layout="responsive"
                objectFit="cover"
                alt=""
              />
            </Box>
            <Flex
              sx={{
                flexDirection: 'column',
                alignItems: [
                  'center',
                  index % 2 === 0 ? 'flex-start' : 'flex-end',
                ],
                justifyContent: 'flex-start',
                width: ['100%', '40%'],
                my: [3, 5],
                pl: index % 2 === 0 ? [2, 5] : 0,
                pr: index % 2 === 0 ? 0 : [2, 5],
              }}
            >
              <Heading
                sx={{
                  fontSize: 3,
                  mb: 2,
                }}
              >
                {t(`landing.argumentation.${arg.translation}.title`)}
              </Heading>
              <Box
                sx={{
                  textAlign: ['center', index % 2 === 0 ? 'left' : 'right'],
                }}
              >
                <Text>
                  {t(`landing.argumentation.${arg.translation}.description`)}
                </Text>
                {arg.translation === 'image2' && (
                  <Box mt={2}>
                    <Link
                      href="https://apuyou.fotokorner.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ color: 'black' }}
                    >
                      {t(`landing.argumentation.sample`)} →
                    </Link>
                  </Box>
                )}
              </Box>
            </Flex>
          </Flex>
        ))}
      </Section>
    </Box>
  );
};

export default Argumentation;
