import React, { useState } from 'react';

import Layout from 'components/landing/layout';
import SEO from 'components/landing/seo';
import Hero from 'components/landing/hero';
import Features from 'components/landing/features';
import Argumentation from 'components/landing/argumentation';
import Pricing from 'components/landing/pricing';

const IndexPage = () => {
  const [playHero, setPlayHero] = useState(false);

  return (
    <Layout setPlayHero={setPlayHero}>
      <SEO />

      <Hero playHero={playHero} />
      <Features />
      <Argumentation />
      <Pricing />
    </Layout>
  );
};

export default IndexPage;
