import { Box } from 'theme-ui';
import { keyframes } from '@emotion/react';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';

import logo from 'images/logo.svg';

const appear = keyframes`
  from, 50% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const HeroLogo = ({ visible }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: visible ? 'flex' : 'none',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        height: '100%',
        animation: `${appear} 1.4s ease-in-out 1`,
      }}
    >
      <Image src={logo} alt="fotokorner" height="60" width="262" />
      <Box
        sx={{
          paddingTop: 2,
          marginX: 'auto',
          textAlign: 'center',
          color: 'white',
          textTransform: 'lowercase',
          fontWeight: 'bold',
        }}
      >
        {t('landing.meta.description')}
      </Box>
    </Box>
  );
};

export default HeroLogo;
