import React, { useEffect, useState } from 'react';
import { Heading, Flex, Box, Text, Link, Button } from 'theme-ui';
import Image from 'next/image';
import NextLink from 'next/link';
import { Trans, useTranslation } from 'react-i18next';

import Section from './section';
import slice from 'images/slice-f4.svg';
import SetupLink from 'components/billing/setuplink';

const Pricing = () => {
  const [currency, setCurrency] = useState('USD');
  const { t } = useTranslation();

  useEffect(() => {
    fetch('/api/detect')
      .then((res) => res.json())
      .then((data) => setCurrency(data.currency));
  }, []);

  const portfolioPrice =
    currency === 'USD'
      ? { price: '10', currency: '$' }
      : { price: '9', currency: '€' };

  const plans = [
    {
      id: 'portfolio',
      price: t('landing.pricing.price', portfolioPrice),
      level: 1,
      available: true,
    },
    {
      id: 'business',
      price: t('landing.pricing.coming_soon'),
      level: 3,
      available: false,
    },
  ];

  const nbFeatures = 4;

  const CurrencyButton = ({ currency: buttonCurrency }) => (
    <Button
      variant="link"
      onClick={() => setCurrency(buttonCurrency)}
      sx={{
        textDecoration: currency === buttonCurrency ? 'underline' : 'inherit',
      }}
    >
      {buttonCurrency}
    </Button>
  );

  const Plan = ({ plan }) => (
    <Box
      sx={{
        width: ['100%', '100%', '50%'],
        marginX: 4,
        marginY: [4, 3],
        backgroundColor: 'white',
        textAlign: 'center',
        boxShadow: 'large',
      }}
    >
      <Box px={2} py={3} bg="brand">
        <Flex sx={{ justifyContent: 'space-between' }}>
          <Text sx={{ width: '33%' }} />
          <Text
            sx={{
              color: 'white',
              fontSize: 0,
              textTransform: 'uppercase',
            }}
          >
            {t(`landing.pricing.${plan.id}.name`)}
          </Text>
          <Text
            sx={{
              color: 'white',
              fontSize: 0,
              width: '33%',
              textAlign: 'right',
            }}
          >
            {plan.available && (
              <>
                <CurrencyButton currency="EUR" /> |{' '}
                <CurrencyButton currency="USD" />
              </>
            )}
          </Text>
        </Flex>
        <Heading
          sx={{
            fontSize: 4,
          }}
          color="white"
        >
          {plan.price}
        </Heading>
      </Box>
      <Box px={3} py={4}>
        <Box
          sx={{
            fontSize: 2,
            mx: 3,
            mb: 4,
            fontWeight: 'bold',
          }}
        >
          {t(`landing.pricing.${plan.id}.tagline`)}
        </Box>
        {[...Array(nbFeatures).keys()].map((index) => (
          <Box
            key={index}
            m={4}
            sx={
              plan.level >= index
                ? {}
                : {
                    textDecoration: 'line-through',
                    color: '#767676',
                  }
            }
          >
            <Trans i18nKey={`landing.pricing.feature${index}`} />
          </Box>
        ))}
        <Flex sx={{ justifyContent: 'center' }}>
          {plan.available && (
            <SetupLink as={NextLink} href="/register" passHref>
              <Link mt={2} variant="button" display="block">
                {t('landing.pricing.get_started')}
              </Link>
            </SetupLink>
          )}
          {!plan.available && (
            <Button mt={2} disabled>
              {t('landing.pricing.get_started')}
            </Button>
          )}
        </Flex>
        <Box
          sx={{
            marginTop: 1,
            height: '1em',
          }}
        >
          {plan.available && t('landing.pricing.trial')}
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          overflow: 'hidden',
          zIndex: 0,
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
      >
        <Image
          src="/landing/chamonix.jpg"
          layout="fill"
          objectFit="cover"
          objectPosition="bottom"
          alt=""
        />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          zIndex: 1,
          top: 0,
          left: 0,
          width: '100%',
          height: 150,
          backgroundImage: `url(${slice})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center bottom',
          paddingTop: [100, 350],
        }}
      ></Box>
      <Box
        sx={{
          position: 'relative',
          zIndex: 2,
          paddingBottom: [0, 150],
        }}
      >
        <Section>
          <Flex
            sx={{
              flexDirection: ['column', 'row'],
              justifyContent: 'center',
              alignItems: ['center', 'flex-start'],
              marginY: 4,
              paddingX: [3, 5],
            }}
          >
            {plans.map((plan) => (
              <Plan plan={plan} key={plan.id} />
            ))}
          </Flex>
        </Section>
      </Box>
    </Box>
  );
};

export default Pricing;
