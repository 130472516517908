import React, { useState, useEffect } from 'react';
import { Flex } from 'theme-ui';

import Section from './section';
// import HeroText from './herotext';
// import HeroImage from './heroimage';
import HeroLogo from './herologo';

const STEP_LOADING = -1,
  STEP_TEXT = 0,
  // STEP_IMAGE = 1,
  STEP_LOGO = 2;

const Hero = ({ playHero }) => {
  const [current, setCurrent] = useState(STEP_LOGO);
  // const next = () =>
  //   setCurrent((current) => (current < STEP_LOGO ? current + 1 : STEP_LOGO));

  useEffect(() => {
    if (playHero && current === STEP_LOADING) {
      setCurrent(STEP_TEXT);
    }
  }, [playHero, current]);

  return (
    <Section>
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: [275, 250],
          color: 'white',
          textAlign: 'center',
        }}
      ></Flex>
      <Flex
        sx={{
          alignSelf: 'center',
          width: '42em',
          backgroundColor: 'brand',
          height: ['12em', '18em'],
          maxWidth: '100%',
          boxShadow: 'large',
          opacity: current > STEP_LOADING ? 1 : 0,
          transition: 'opacity .2s ease-in-out',
        }}
      >
        {/* {current === STEP_TEXT && <HeroText next={next} />} */}
        {/* <HeroImage next={next} visible={current === STEP_IMAGE} /> */}
        <HeroLogo visible={current === STEP_LOGO} />
      </Flex>
    </Section>
  );
};

export default Hero;
