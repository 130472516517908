import React from 'react';
import Image from 'next/image';
import { Heading, Text, Flex } from 'theme-ui';
import { useTranslation } from 'react-i18next';

import Section from './section';
import iconGalleries from 'images/galleries.svg';
import iconSafe from 'images/safe.svg';
import iconStorage from 'images/storage.svg';

const Features = () => {
  const { t } = useTranslation();
  const features = [
    {
      id: 'galleries',
      icon: iconGalleries,
      width: 36,
      height: 36,
    },
    {
      id: 'safe',
      icon: iconSafe,
      width: 31,
      height: 36,
    },
    {
      id: 'storage',
      icon: iconStorage,
      width: 25,
      height: 33,
    },
  ];

  return (
    <Section>
      <Flex
        sx={{
          flexDirection: ['column', 'column', 'row'],
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {features.map((feature) => (
          <Flex
            key={feature.id}
            sx={{
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: ['100%', '100%', '33%'],
              mt: [4, 5],
              mb: [4, 4],
              paddingX: 3,
            }}
          >
            <Image
              src={feature.icon}
              width={feature.width}
              height={feature.height}
              alt=""
            />
            <Heading sx={{ fontSize: 3, my: 3 }}>
              {t(`landing.features.${feature.id}.title`)}
            </Heading>
            <Text sx={{ textAlign: 'center' }}>
              {t(`landing.features.${feature.id}.description`)}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Section>
  );
};

export default Features;
